import { Routes, Route } from "react-router-dom";
import {
  Home,
  Rahbariyat,
  IlmiyKengash,
  OurTeam,
  History,
  Products,
  News,
  Fotos,
  Videos,
  Oav,
  PageNotFound,
  Memorandum,
} from "./pages/index";
import Connection from "./pages/Home/Connection";
import React, { lazy, useEffect, useState } from "react";
import Departments from "./pages/Home/Departments";
import BolimlarDetails from "./components/BolimlarDetails";
import ProductsDetails from "./components/ProductsDetails";
import UseServices from "./components/UseServices";
import ResourceDetails from "./components/ResourceDetails";
import NewsDetails from "./components/NewsDetails";
import VideoDetails from "./components/VideosDetail";
import Layout from "./components/Layout/Layout";
import JobVacancy from "./pages/OpenInfo/JobVacancy";
import OrgStructure from "./pages/OpenInfo/OrgStructure";
import Appeal from "./pages/Corruption/Appeal";
import AntiCorruption from "./pages/Corruption/AntiCorruption";
import Channels from "./pages/Corruption/Channels";
import OpenDocs from "./pages/OpenInfo/OpenDocs";
import Info from "./pages/OpenInfo/Purchases/Info";
import CenterNews from "./pages/InfoCommunication/CenterNews";
import EditTextModal from "./components/modal/editTextModal";
import {Register, Tour} from "./pages";

// const Home = lazy(() => import('./pages/Home/Home'));

function App() {
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);
  const [slug, setSlug] = useState("");

  useEffect(() => {
    const selectableTextArea = document.querySelector("#root");
    const editShareBtn = document.querySelector("#edit-share-btn");

    const selectableTextAreaMouseUp = (evt) => {
      setTimeout(() => {
        const selectedText = window.getSelection().toString().trim();
        if (selectedText.length) {
          const x = evt.pageX;
          const y = evt.pageY;
          const editShareBtnWidth = Number(
            getComputedStyle(editShareBtn).width.slice(0, -2)
          );
          const editShareBtnHeight = Number(
            getComputedStyle(editShareBtn).height.slice(0, -2)
          );
          editShareBtn.style.left = `${x - editShareBtnWidth * 0.5}px`;
          editShareBtn.style.top = `${y - editShareBtnHeight * 1.25}px`;
          editShareBtn.style.display = "flex";
          editShareBtn.classList.add("btnEntrance");
        }
      }, 0);
    };

    const documentMouseDown = (evt) => {
      if (
        getComputedStyle(editShareBtn).display === "flex" &&
        evt.target.id !== "edit-share-btn"
      ) {
        editShareBtn.style.display = "none";
        editShareBtn.classList.remove("btnEntrance");
        window.getSelection().empty();
      }
    };

    const editShareBtnClick = (evt) => {
      const text = window.getSelection().toString().trim();
      const url = window.location.href;
      editShareBtn.style.display = "none";
      setText(text);
      setOpen(!open);
      setSlug(url);
    };

    selectableTextArea.addEventListener("mouseup", selectableTextAreaMouseUp);
    document.addEventListener("mousedown", documentMouseDown);
    editShareBtn.addEventListener("click", editShareBtnClick);

    return () => {
      selectableTextArea.removeEventListener(
        "mouseup",
        selectableTextAreaMouseUp
      );
      document.removeEventListener("mousedown", documentMouseDown);
      editShareBtn.removeEventListener("click", editShareBtnClick);
    };
  }, [open]);

  return (
    <>
      <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="services/:id" element={<BolimlarDetails />} />
          <Route path="news/:id" element={<NewsDetails />} />
          <Route path="products/:id" element={<ProductsDetails />} />
          <Route path="useServices/:id" element={<UseServices />} />
          <Route path="resource/:id" element={<ResourceDetails />} />
          <Route path="video/:id" element={<VideoDetails />} />
          <Route path="rahbariyat" element={<Rahbariyat />} />
          <Route path="ilmiykengash" element={<IlmiyKengash />} />
          <Route path="team" element={<OurTeam />} />
          <Route path="tarix" element={<History />} />
          <Route path="products" element={<Products />} />
          {/* <Route path='resurslar' element={<Resurslar/>}/> */}
          <Route path="yangiliklar" element={<News />} />
          <Route path="cn" element={<CenterNews />} />
          <Route path="foto" element={<Fotos />} />
          <Route path="video" element={<Videos />} />
          <Route path="oav" element={<Oav />} />
          <Route path="memorandum" element={<Memorandum />} />
          <Route path="contacts" element={<Connection />} />
          <Route path="services" element={<Departments />} />
          <Route path="job-vacancy" element={<JobVacancy />} />
          <Route path="org-structure" element={<OrgStructure />} />
          <Route path="murojaat" element={<Appeal />} />
          <Route path="anti-korrupsiya" element={<AntiCorruption />} />
          <Route path="hisobotlar" element={<AntiCorruption />} />
          <Route path="aloqa-kanallari" element={<Channels />} />
          <Route path="xisobotlar" element={<OpenDocs />} />
          <Route path="xujjatlar" element={<OpenDocs />} />
          {/* <Route path='statute' element={<Statute/>}/> */}
          <Route path="purches-info" element={<Info />} />
          <Route path="select-info" element={<Info />} />
          <Route path="select-result" element={<Info />} />

          {/*<Route path="3dtour" element={<Tour />} />*/}

          <Route path="register" element={<Register/>} />
        </Route>
      </Routes>
      {/*<Footer/>*/}
      <EditTextModal open={open} text={text} setOpen={setOpen} slug={slug} />
    </>
  );
}

export default App;
