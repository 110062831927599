import { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { switchLang } from "../../redux/actions/actions";
import MenuItems from "./MenuItems";
import logo from "../../assets/logo.svg";
import { useTranslation } from "react-i18next";
// import Rek15 from "../../assets/rek15.png";
// import Rek25 from "../../assets/rek25.png";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import { Autoplay } from "swiper";
import VanillaTilt from "vanilla-tilt";

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

const Navbar = () => {
  const menu_items = [
    {
      name: "Biz haqimizda",
      ru: "О нас",
      uz: "Biz haqimizda",
      en: "About us",
      id: 13,
      children: [
        {
          name: "Rahbariyat",
          ru: "Руководство",
          uz: "Rahbariyat",
          en: "Leadership",
          id: 1,
          path: "/rahbariyat",
        },
        {
          name: "Ilmiy kengash",
          ru: "Ученый совет",
          uz: "Ilmiy kengash",
          en: "Scientific council",
          path: "/ilmiykengash",
          id: 2,
        },
        {
          name: "Bizning jamoa",
          ru: "Наша команда",
          uz: "Bizning jamoa",
          en: "Our team",
          path: "/team",
          id: 3,
        },
        {
          name: "Tarix",
          ru: "История",
          uz: "Tarix",
          en: "History",
          path: "/tarix",
          id: 4,
        },
        // {
        //   name: "Bo'limlar",
        //   ru: "Отделы",
        //   uz: "Bo'limlar",
        //   en: "Departments",
        //   id: 14,
        //   path: "/services",
        // },
      ],
    },
    {
      name: "Xizmatlar",
      path: "/products",
      id: 5,
      ru: "Услуги",
      uz: "Xizmatlar",
      en: "Services",
      href: "https://xizmatlar.unicon.uz/",
      // title_uz:
      //   "Tez orada shartnomani masofaviy rasmiylashtirish va xizmatdan foydalanish imkoniyati ishga tushiriladi.",
      // title_ru:
      //   "В скором времени дистанционное заключение договора и подключение  услуг будет доступен.",
    },
    {
      name: "Havolalar",
      id: 24,
      uz: "Havolalar",
      ru: "Ссылки",
      en: "Links",
      children: [
        {
          name: "Data markaz",
          id: 224,
          ru: "Дата центр",
          uz: "Data markaz",
          en: "Data center",
          href: "https://dc.unicon.uz/",
        },
        {
          name: "STT",
          id: 225,
          ru: "БОС",
          uz: "STT",
          en: "BOS",
          href: "https://stt2.unicon.uz/#/",
        },
        {
          name: "Sertifikatsiya",
          id: 226,
          ru: "Сертификация",
          uz: "Sertifikatsiya",
          en: "Certification",
          href: "https://certificate.unicon.uz/",
        },
      ],
    },
    {
      name: "Axborot Xizmati",
      ru: "Пресс-центр",
      uz: "Axborot Xizmati",
      en: "Information Service",
      id: 15,
      children: [
        {
          id: 31,
          ru: "Новости",
          uz: "Yangiliklar",
          en: "News",
          name: "Yangiliklar",
          path: "/yangiliklar",
        },
        {
          id: 32,
          ru: "Новости центра",
          uz: "Markaz yangiliklari",
          en: "Center news",
          name: "Markaz yangiliklari",
          path: "/cn",
        },
        {
          id: 33,
          ru: "Фото репортаж",
          uz: "Foto reportaj",
          en: "Photo report",
          name: "Foto reportaj",
          path: "/foto",
        },
        {
          id: 34,
          ru: "Видео репортаж",
          uz: "Video reportaj",
          en: "Video report",
          name: "Video reportaj",
          path: "/video",
        },
        {
          id: 35,
          ru: "СМИ",
          uz: "OAV",
          en: "mass-media",
          name: "OAV",
          path: "/oav",
        },
        {
          id: 455,
          ru: "Меморандум",
          uz: "Memorandum",
          en: "Memorandum",
          name: "Memorandum",
          path: "/memorandum",
        },
      ],
    },
    {
      name: "Korrupsiyaga qarshi kurash",
      ru: "БОРЬБА С КОРРУПЦИЕЙ",
      uz: "Korrupsiyaga qarshi kurash",
      en: "Anti-corruption",
      id: 19,
      children: [
        {
          id: 51,
          ru: 'Обращение генерального директора ООО "UNICON.UZ"\n',
          uz: "“UNICON.UZ” MChJ Bosh direktori MUROJAATI",
          en: 'ADDRESS of the General Director of "UNICON.UZ" LLC',
          name: "direktor murojaati",
          path: "/murojaat",
        },
        {
          id: 53,
          ru: "Ведомственные документы по борьбе с коррупцией\n",
          uz: "Korrupsiyaga qarshi kurashish bo‘yicha idoraviy hujjatlar",
          en: "Departmental anti-corruption documents",
          name: "Anti korrupsiya",
          path: "/anti-korrupsiya",
        },
        {
          id: 54,
          ru: "Обращение по предотвращению коррупции\n",
          uz: "Korrupsiyani oldini olish bo‘yicha MUROJAAT",
          en: "corruption prevention appeal",
          name: "Korrupsiyani oldini olish bo‘yicha MUROJAAT",
          path: "/aloqa-kanallari",
        },
        {
          id: 55,
          ru: "Отчеты",
          uz: "HISOBOTLAR",
          en: "REPORTS",
          name: "HISOBOTLAR",
          path: "/hisobotlar",
        },
      ],
    },
    {
      name: "Ochiq malumotlar",
      ru: "ОТКРЫТЫЕ ДАННЫЕ\n",
      uz: "Ochiq malumotlar",
      en: "Open data",
      id: 20,
      children: [
        // {
        //   id: 31,
        //   ru: "Xujjatlar",
        //   uz: "Xujjatlar",
        //   name: "Xujjatlar",
        //   path: "/xujjatlar",
        // },
        {
          id: 63,
          ru: "Отчеты",
          uz: "Hisobotlar",
          en: "Reports",
          name: "Hisobotlar",
          path: "/xisobotlar",
        },
        {
          id: 64,
          ru: "Структура",
          uz: "Tashkiliy tuzilma",
          en: "Organizational structure",
          name: "Tashkiliy tuzilma",
          path: "/org-structure",
        },
        // {
        //   name: "Resurslar",
        //   ru: "Ресурсы",
        //   uz: "Resurslar",
        //   path: "/resurslar",
        //   id: 6,
        // },
        {
          id: 65,
          ru: "Вакансии",
          uz: "Bo'sh ish o'rinlari",
          en: "Vacancies",
          name: "Bo'sh ish o'rinlari",
          path: "/job-vacancy",
        },
        {
          id: 66,
          ru: "Закупки",
          uz: "Xaridlar",
          en: "Purchases",
          name: "Xaridlar",
          // path: "/haridlar",
          children: [
            // {
            //   id: 37,
            //   ru: "Nizom",
            //   uz: "Nizom",
            //   name: "Nizom",
            //   path: "/statute",
            // },
            {
              id: 68,
              ru: "Отбор предложений\n",
              uz: "Takliflar tanlovi",
              en: "A selection of offers",
              name: "Takliflar tanlovi",
              path: "/purches-info",
            },
            {
              id: 69,
              ru: "Тендеры",
              uz: "Tenderlar",
              en: "Tenders",
              name: "Tenderlar",
              path: "/select-info",
            },
            {
              id: 70,
              ru: "Результаты",
              uz: "Natijalar",
              en: "Results",
              name: "Natijalar",
              path: "/select-result",
            },
          ],
        },
      ],
    },
    // {
    //   name: "Virtual sayoxat",
    //   uz: "Virtual sayoxat",
    //   ru: "Виртуальный тур",
    //   en: "Virtual trip",
    //   id: 78,
    //   path: "/3dtour"
    // },
    {
      name: "Bog'lanish",
      uz: "Bog'lanish",
      ru: "Контакты",
      en: "Contact us",
      id: 77,
      path: "/contacts",
    },
  ];
  const depthLevel = 0;
  const dispatch = useDispatch();
  const [navOpen, setNavOpen] = useState(false);
  const location1 = useLocation();
  const { location } = useSelector((state) => state.location);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleClick = () => {
    setNavOpen(!navOpen);
  };

  const handleChange = (e) => {
    dispatch(switchLang(e.target.value));
  };

  useEffect(() => {
    setNavOpen(false);
  }, [pathname]);

  const [move, setMove] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setMove(true);
      } else {
        setMove(false);
      }
    });
  }, []);

  return (
    <>
      <nav
        className={
          location1.pathname === "/" && move
            ? `py-5  md:hidden desktop z-50 fixed w-full text-[#3A375E] duration-300 ${
                move
                  ? "backdrop-blur-2xl pt-5 bg-white/80 text-[#3A375E]"
                  : `${pathname !== "/" && "backdrop-blur-2xl bg-white/80"}`
              }`
            : location1.pathname !== "/"
            ? `py-5  md:hidden desktop z-50 fixed w-full text-[#3A375E] duration-300 ${
                move
                  ? "backdrop-blur-2xl pt-5 bg-white/80 text-[#3A375E]"
                  : `${pathname !== "/" && "backdrop-blur-2xl bg-white/80"}`
              }`
            : `py-5 pt-5 md:hidden desktop fixed z-50 w-full duration-300 ${
                move
                  ? "backdrop-blur-2xl bg-white/80 text-[#3A375E]"
                  : `${pathname !== "/" && "backdrop-blur-2xl bg-white/80"}`
              }`
        }
      >
        {/* <Swiper
          direction={"horizontal"}
          slidesPerView={"auto"}
          spaceBetween={30}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          className={`mySwiper w-full z-[999] ${move ? "-translate-y-5" : "-translate-y-5"}`}
        >
          <Tilt>
            <SwiperSlide>
              <a
                className={
                  location1.pathname === "/" && move
                    ? "hidden"
                    : "flex z-[999] h-[80px] lg:h-auto object-cover bg-[#464897]"
                }
                target={"_blank"}
                rel="noreferrer"
                href={"https://dc.unicon.uz/"}
              >
                <img
                  className={"mx-auto"}
                  width={1200}
                  src={Rek25}
                  alt={"chegirma 25%"}
                />
              </a>
            </SwiperSlide>
          </Tilt>
          <Tilt>
            <SwiperSlide>
              <a
                className={
                  location1.pathname === "/" && move
                    ? "hidden"
                    : "flex z-[999] h-[80px] lg:h-auto object-cover bg-[#464897]"
                }
                target={"_blank"}
                rel="noreferrer"
                href={"https://dc.unicon.uz/"}
              >
                <img
                  className={"mx-auto"}
                  width={1200}
                  src={Rek15}
                  alt={"chegirma 15%"}
                />
              </a>
            </SwiperSlide>
          </Tilt>
        </Swiper> */}

        {/* <div
          className={
            location1.pathname === "/" && move
              ? "bg-grey p-1 absolute top-0 overflow-x-hidden w-full"
              : "hidden"
          }
        >
          <p className="text-white animation mr-8">{t("test")}</p>
        </div> */}
        <div className="container mx-auto">
          <div className="flex items-center justify-between">
            <Link to="/" className="logo w-[190px] h-[32px]">
              <img src={logo} alt="" className="w-full h-full" />
            </Link>
            <div className="flex items-center gap-7">
              <div className="flex items-center gap-1">
                <div className="px-2 flex items-center gap-3">
                  <div
                    className={`w-[1px] h-9 ${
                      move ? "bg-white" : `${pathname !== "/" && "bg-white"}`
                    }`}
                  ></div>
                  <a
                    className={`flex items-center mr-auto ${
                      move || pathname !== "/" ? "text-logo" : "text-bd"
                    }`}
                    href="https://front.unicon.uz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="ml-1 font-semibold">{t("beta")}</span>
                  </a>
                  <div
                    className={`w-[1px] h-9 ${
                      move ? "bg-white" : `${pathname !== "/" && "bg-white"}`
                    }`}
                  ></div>
                </div>
                {/* <span className='text-3xl'>
                    <i className="ri-global-line"></i>
                </span> */}
                <select
                  className={`outline-none text-xl font-bold transparent`}
                  defaultValue={location}
                  onChange={handleChange}
                >
                  <option
                    className={`font-bold bg-white ${
                      move || pathname !== "/" ? "" : ""
                    }`}
                    style={{
                      display:
                        location === "ru" || location === "en" ? "" : "none",
                    }}
                    value="uz"
                  >
                    uz
                  </option>
                  <option
                    className={`font-bold ${
                      move || pathname !== "/" ? "" : ""
                    }`}
                    style={{
                      display:
                        location === "uz" || location === "en" ? "" : "none",
                    }}
                    value="ru"
                  >
                    ru
                  </option>
                  <option
                    className={`font-bold ${
                      move || pathname !== "/" ? "" : ""
                    }`}
                    style={{
                      display:
                        location === "uz" || location === "ru" ? "" : "none",
                    }}
                    value="en"
                  >
                    en
                  </option>
                </select>
              </div>
            </div>
          </div>

          {/* border-t-[#3A375E] */}
          <div
            className={`xl2:pt-1 ${
              move
                ? "border-t-[1px] border-t-white"
                : `${pathname !== "/" && "border-t-[1px] border-t-white"}`
            }`}
          >
            <ul className="menus flex justify-between lg3:justify-start whitespace-nowrap flex-wrap">
              {menu_items.map((menu, index) => (
                <MenuItems items={menu} key={index} depthLevel={depthLevel} />
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/*mobile*/}
      <nav
        className={`py-1 w-full mobile`}
        style={{ padding: navOpen ? "1.9rem 0" : "" }}
      >
        <div className="container mx-auto">
          <div className="flex flex-col justify-between">
            <div className="flex justify-between items-center logos">
              <Link to="/" className="logo w-[190px] h-[25px] xs:w-[150px]">
                <img
                  src={logo}
                  alt="logo rasm"
                  className={`w-full h-full duration-300 ${
                    navOpen
                      ? "opacity-0 duration-300 delay-100"
                      : "duration-300 delay-100"
                  }`}
                />
              </Link>
              <div className={`flex items-center gap-5 relative xs:gap-3`}>
                <div
                  className={`flex justify-center items-center ${
                    navOpen ? "md:absolute md:right-12 language -z-10" : ""
                  }`}
                >
                  {/* <span className='text-3xl xs:text-2xl'>
                      <i className="ri-global-line"></i>
                  </span> */}
                  <select
                    className="outline-none text-xl font-bold bg-transparent"
                    defaultValue={location}
                    onChange={handleChange}
                  >
                    <option
                      className="font-bold"
                      style={{
                        display:
                          location === "ru" || location === "en" ? "" : "none",
                      }}
                      value="uz"
                    >
                      uz
                    </option>
                    <option
                      className="font-bold"
                      style={{
                        display:
                          location === "uz" || location === "en" ? "" : "none",
                      }}
                      value="ru"
                    >
                      ru
                    </option>
                    <option
                      className="font-bold"
                      style={{
                        display:
                          location === "uz" || location === "ru" ? "" : "none",
                      }}
                      value="en"
                    >
                      en
                    </option>
                  </select>
                </div>
                <div
                  className={`menu-toggle ${
                    navOpen ? "fixed z-[99999] top-1 right-5" : ""
                  }`}
                  onClick={handleClick}
                >
                  <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
                    <span
                      className={navOpen ? "lineTop spin" : "lineTop"}
                    ></span>
                    <span
                      className={navOpen ? "lineBottom spin" : "lineBottom"}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`left_nav fixed w-[50%] xs:w-[70%] duration-500 bg-white left-0 top-0 h-full z-[9999] overflow-y-scroll overflow-x-hidden delay-75 ${
                navOpen ? "left-0" : "-left-[100%] hidden"
              }`}
            >
              <div className="flex justify-center items-center gap-1 px-5 py-1 mt-4">
                <div className="px-2 border border-b-logo hidden items-center gap-3 rounded-lg">
                  <span className="text-3xl xs:text-2xl">
                    <i className="ri-search-line"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="search..."
                    className={"outline-none"}
                  />
                </div>
              </div>
              {navOpen && (
                <ul className="menus flex flex-col justify-center items-start md:h-[65%] xs:h-[75%]">
                  {menu_items.map((menu, index) => (
                    <MenuItems
                      items={menu}
                      key={index}
                      depthLevel={depthLevel}
                    />
                  ))}
                </ul>
              )}
            </div>
            <div
              className={`fixed top-0 left-0 w-full h-full pointer-events-none z-[999] duration-300`}
              style={{
                background: navOpen ? "rgba(0, 49, 76, 0.58)" : "",
              }}
            ></div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
