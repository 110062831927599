import React from 'react';
import CommonSection from "../../components/Layout/CommonSection";

function Tariflar() {
  return (
    <>
      <div className='tariflar'>
        <CommonSection title={'tariflar'} breadCrumb={'tariflar'}/>
        <div className="container mx-auto">
          <div className="box py-7">
            coming soon...
          </div>
        </div>
      </div>
    </>
  );
}

export default Tariflar;
