import React from 'react';
import CommonSection from "../../components/Layout/CommonSection";

function Takliflar() {
  return (
    <>
      <div>
        <CommonSection title={'takliflar tanlovi'} breadCrumb={'takliflar tanlovi'}/>
        <div className="container mx-auto">
          <div className="box py-7">
            <div className="py-4 px-3 rounded-lg" style={{boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)"}}>
              <div>
                <div className="flex flex-col gap-4">
                  <h5
                    className="text-hover text-left">Holati: o'z kuchida</h5>
                  <h6 className="text-logo text-lg text-left">
                    "UNICON.UZ" Davlat unitar korxonasi elektron komponentlar, radiodetallar va
                    butlovchi qismlarni yetkazib beruvchilar o‘rtasida eng muqobil takliflar bo‘yicha
                    tanlov e'lon qiladi
                  </h6>
                  <div className="wpb_text_column wpb_content_element ">
                    <div className="wpb_wrapper">
                      <p>
                        <strong>Таклифларни қуйидаги манзилга юбориш мумкин бўлади</strong>: 100187, Тошкент шаҳри,
                        Мирзо-Улуғбек тумани, Мингбулоқ кўчаси 38.
                        <br/>
                        <strong>Саволлар бўйича тел</strong>: (93) 510 43 82 рақамига мурожаат қилишингиз ёки
                        сўровингизни szi@unicon.uz электрон почта манзилига жўнатишингиз мумкин.
                      </p>
                    </div>
                  </div>
                  <div>
                    <h1><strong>13.07.2022 – 22.07.2022</strong></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Takliflar;
