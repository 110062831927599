import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNews, getSingleNews } from "../redux/actions/actions";
import CommonSection from "./Layout/CommonSection";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectCoverflow } from "swiper";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import moment from "moment";

const NewsDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleNews(id));
    dispatch(getNews({ type }));
    changeWindow();
    console.log("single");
  }, [id, dispatch]);

  const { singleNew } = useSelector((state) => state.news);
  const { content_ru, content_uz, content_en } = useSelector(
    (state) => state.news.singleNew
  );
  const { news } = useSelector((state) => state.news);
  const { location } = useSelector((state) => state.location);
  const { t } = useTranslation();
  const [width, setWidth] = useState(3);

  const type = "yangiliklar?last_count=6";

  useEffect(() => window.scrollTo(0, 0), []);

  const changeWindow = () => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 768) {
        return setWidth(1);
      } else if (window.innerWidth < 1024) {
        return setWidth(2);
      } else {
        return setWidth(3);
      }
    });
  };

  return (
    <>
      <div>
        <CommonSection
          title={location === "uz" ? singleNew.title_uz : location === 'ru' ? singleNew.title_ru : singleNew.title_en}
          // breadCrumb={
          //   location === "uz" ? singleNew.title_uz : singleNew.title_ru
          // }
        />
        <div className="container flex mx-auto">
          <div className="w-1/4 pr-5 pt-5 lg:hidden">
            <div className="sticky top-0">
              <h3 className="text-2xl font-medium text-logo mb-8">
                {t("news")}
              </h3>

              <div>
                {news &&
                  news?.result?.map((item, idx) => (
                    <Link
                      key={idx}
                      className="flex min-h-[90px] pb-5 border-b border-[#ccc] mb-5"
                      to={`/news/${item.id}`}
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      {item?.files[0]?.is_video ? (
                            <iframe
                              className="object-cover block h-[70px]"
                              title="video"
                              src={`${item?.files[0]?.video_url}`}
                              width={"100%"}
                              height={"100%"}
                              style={{
                                height: "70px",
                                width: "70px",
                                objectFit: "cover",
                              }}
                              frameBorder={0}
                              allowFullScreen={true}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            />
                          ) : (
                            <img
                              className="object-cover block h-[70px]"
                              width={"70"}
                              height={"70"}
                              src={`${item?.files[0]?.file}`}
                              alt="rasm"
                            />
                          )}
                      <div className="pl-4">
                        <p className="text-logo hover:text-hover text-xs uppercase mb-2">
                          {location === "uz"
                            ? item?.title_uz?.substring(0, 70)
                            : location === 'ru' ? item?.title_ru?.substring(0, 70)
                            : item?.title_en?.substring(0, 70)}
                        </p>
                        <div
                          className="text-logo-secondary hover:text-hover text-xs"
                          dangerouslySetInnerHTML={{
                            __html:
                              location === "uz"
                                ? item?.content_uz?.substring(3, 70)
                                : location === 'ru' ? item?.content_ru?.substring(3, 70)
                                : item?.content_en?.substring(3, 70),
                          }}
                        />
                      </div>
                    </Link>
                  ))}
              </div>
            </div>
          </div>

          <div className="w-3/4 lg:w-full box py-5 news_details">
            <div className="images w-full gap-10 flex">
              {/*<div className="left w-2/4">*/}
              {/*  <img*/}
              {/*    src={singleNew.images === undefined ? "" : `${singleNew?.images[mainImage]?.image}`}*/}
              {/*    alt=""/>*/}
              {/*</div>*/}
              <div className="right w-full">
                <Swiper
                  direction={"horizontal"}
                  pagination={{
                    clickable: true,
                  }}
                  coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  spaceBetween={5}
                  modules={[Pagination, EffectCoverflow, Navigation]}
                  slidesPerView={"auto"}
                  navigation={true}
                  className="mySwiper w-full"
                >
                  {singleNew &&
                    singleNew?.files?.map((item) => (
                      <SwiperSlide key={item?.id}>
                        {item?.is_video ? (
                          <iframe
                            title="video"
                            src={`${item?.video_url}`}
                            width={"100%"}
                            height={"100%"}
                            style={{
                              height: "230px",
                              width: "100%",
                              objectFit: "cover",
                            }}
                            frameBorder={0}
                            allowFullScreen={true}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          />
                        ) : (
                          <img
                            src={`${item?.file}`}
                            alt="img"
                            style={{
                              height: "auto",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </SwiperSlide>
                    ))}
                </Swiper>
              </div>
            </div>
            <div
              className="content py-5 rounded-lg"
              // style={{ boxShadow: "0 0 20px 0 rgb(24 50 67 / 12%)" }}
            >
              <div className="p-4">
                {location === "uz"
                  ? parse(`${content_uz}`)
                  : location === 'ru' ? parse(`${content_ru}`)
                  : parse(content_en)}
                <h1 className="font-bold mt-5 text-end">
                  {moment(singleNew.created_at).format("DD-MM-YYYY HH:mm")}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetails;
